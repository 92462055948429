import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import hljs from 'highlight.js';

@Component({
    selector: 'app-code-block',
    templateUrl: 'code-block.component.html',
    styleUrls: ['code-block.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CodeBlockComponent implements OnInit, AfterViewInit {
    @ViewChild('code')
    readonly codeElement!: ElementRef;

    ngOnInit(): void {
        hljs.highlightAll();
    }

    ngAfterViewInit(): void {
        hljs.highlightElement(this.codeElement.nativeElement);
    }
}
