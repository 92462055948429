export enum LibraryActionsEnum {
    // Books
    unsetBook = '[library] unsetBook',
    fetchBook = '[library] fetchBook',
    fetchBookCancel = '[library] fetchBookCancel',
    fetchBookSuccess = '[library] fetchBookSuccess',
    fetchBooks = '[library] fetchBooks',
    fetchBooksSuccess = '[library] fetchBooksSuccess',

    // Book patching
    patchBookIndex = '[library] patchBookIndex',
    patchBookIndexCancel = '[library] patchBookIndexCancel',
    patchBookIndexSuccess = '[library] patchBookIndexSuccess',

    // Chapters
    unsetChapter = '[library] unsetChapter',
    fetchChapter = '[library] fetchChapter',
    fetchChapterCancel = '[library] fetchChapterCancel',
    fetchChapterSuccess = '[library] fetchChapterSuccess',
    fetchChapters = '[library] fetchChapters',
    fetchChaptersCancel = '[library] fetchChaptersCancel',
    fetchChaptersSuccess = '[library] fetchChaptersSuccess',

    // Documents
    fetchDocument = '[library] fetchDocument',
    fetchDocumentCancel = '[library] fetchDocumentCancel',
    fetchDocumentSuccess = '[library] updateLoadedDocument',

    // Documents For progress
    fetchDocumentProgress = '[library] fetchDocumentProgress',
    fetchDocumentProgressSuccess = '[library] fetchDocumentProgressSuccess',
    fetchDocumentProgressFailed = '[library] fetchDocumentProgressFailed',

    // Algebrakit
    algebrakitFinishSession = '[library] algebrakitFinishSession',
    algebrakitFinishSessionSuccess = '[library] algebrakitFinishSessionSuccess',
    algebrakitFinishSessionFailed = '[library] algebrakitFinishSessionFailed',

    algebrakitNewSession = '[library] algebrakitNewSession',
    algebrakitNewSessionSuccess = '[library] algebrakitNewSessionSuccess',
    algebrakitNewSessionFailed = '[library] algebrakitNewSessionFailed',

    // Drillster
    updateDrillsterSessionScore = '[library] updateDrillsterSessionScore',
    updateDrillsterSessionScoreSuccess = '[library] updateDrillsterSessionScoreSuccess',
    updateDrillsterSessionScoreFailed = '[library] updateDrillsterSessionScoreFailed',

    fetchDrillsterSession = '[library] fetchDrillsterSession',
    fetchDrillsterSessionSuccess = '[library] fetchDrillsterSessionSuccess',
    fetchDrillsterSessionFailed = '[library] fetchDrillsterSessionFailed',

    // Sources
    fetchSources = '[library] fetchSources',
    fetchSourcesSuccess = '[library] fetchSourcesSuccess',
    fetchSourcesFailed = '[library] fetchSourcesFailed',
    addSource = '[library] addSource',
    unsetSources = '[library] unsetSources',
    addSourceSuccess = '[library] addSourceSuccess',
    addSourceFailed = '[library] addSourceFailed',
    addSourceFile = '[library] addSourceFile',
    addSourceFileSuccess = '[library] addSourceFileSuccess',
    addSourceFileFailed = '[library] addSourceFileFailed',
    removeSource = '[library] removeSource',
    removeSourceSuccess = '[library] removeSourceSuccess',
    removeSourceFailed = '[library] removeSourceFailed',

    // Sources Teacher materials
    fetchSourcesTeacherMaterials = '[library] fetchSourcesTeacherMaterials',
    fetchSourcesTeacherMaterialsSuccess = '[library] fetchSourcesTeacherMaterialsSuccess',
    fetchSourcesTeacherMaterialsFailed = '[library] fetchSourcesTeacherMaterialsFailed',
    unsetSourcesTeacherMaterials = '[library] unsetSourcesTeacherMaterials',

    // Search books
    fetchBooksSearchResults = '[library] fetchBooksSearchResults',
    fetchBooksSearchResultsSuccess = '[library] fetchBooksSearchResultsSuccess',
    fetchBooksSearchResultsFailed = '[library] fetchBooksSearchResultsFailed',

    // Search in book
    fetchBookSearchResults = '[library] fetchBookSearchResults',
    fetchBookSearchResultsFailed = '[library] fetchBookSearchResultsFailed',
}
