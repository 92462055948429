import {createAction, props} from '@ngrx/store';
import {HttpErrorResponseInterface} from '@core/interfaces/http-error-response.interface';
import {AppExamMetadata, AppExamStep} from '@component/exam/player/player.service';
import {AppExamActionsEnum} from '@component/exam/redux/exam.enum';
import {ApiExamListInterface, ExamAssignInterface} from '@component/exam/exam.service';
import {ExamActionsFetchExamsFailedInterface, ExamActionsFetchExamsInterface} from '@store/exam';
import {ApiExamsResponseInterface} from '@store/exam/exam-api.contract';
import {HttpMethodsEnum} from '@core/http/methods.enum';

export interface ContentExamMetadataSuccessProperties {
    metadata: AppExamMetadata;
}

export interface ContentExamProperties {
    bookUuid: string;
    documentDpsId: string;
    userId?: number;
    planId?: number;
}

export interface ContentExamPlanProperties extends ContentExamProperties {
    examPlanId: number;
}

export interface StepContentExamProperties extends ContentExamPlanProperties {
    assignmentDpsId: string;
    attempt: number;
    userId?: number;
}

export interface ContentExamFailedProperties<PropertiesType extends ContentExamProperties = ContentExamProperties> extends HttpErrorResponseInterface {
    properties: PropertiesType;
}

export interface ExamCreateOrUpdatePropertiesInterface {
    bookUuid: string;
    exam: ExamAssignInterface;
    mode: HttpMethodsEnum.Put | HttpMethodsEnum.Post;
}

export interface ExamCreateOrUpdateFailedPropertiesInterface extends ExamCreateOrUpdatePropertiesInterface, HttpErrorResponseInterface {
}

export interface AppExamFetchAssignedProperties {
    types: number[];
    year?: number;
    offset?: number;
}

export interface AppExamFetchAssignedFailedProperties extends AppExamFetchAssignedProperties, HttpErrorResponseInterface {
}

export class AppExamActions {
    static readonly metadata = createAction(AppExamActionsEnum.Metadata, props<ContentExamProperties>());

    static readonly metadataCancel = createAction(AppExamActionsEnum.MetadataCancel);

    static readonly metadataSuccess = createAction(AppExamActionsEnum.MetadataSuccess, props<ContentExamMetadataSuccessProperties>());

    static readonly metadataFailed = createAction(AppExamActionsEnum.MetadataFailed, props<HttpErrorResponseInterface>());

    static readonly start = createAction(AppExamActionsEnum.Start, props<ContentExamPlanProperties>());

    static readonly startCancel = createAction(AppExamActionsEnum.StartCancel);

    static readonly startSuccess = createAction(AppExamActionsEnum.StartSuccess, props<ContentExamMetadataSuccessProperties>());

    static readonly startFailed = createAction(AppExamActionsEnum.StartFailed, props<ContentExamFailedProperties>());

    static readonly step = createAction(AppExamActionsEnum.Step, props<StepContentExamProperties>());

    static readonly stepCancel = createAction(AppExamActionsEnum.StepCancel);

    static readonly stepSuccess = createAction(AppExamActionsEnum.StepSuccess, props<{ step: AppExamStep }>());

    static readonly stepFailed = createAction(AppExamActionsEnum.StepFailed, props<ContentExamFailedProperties>());

    static readonly finish = createAction(AppExamActionsEnum.Finish, props<ContentExamPlanProperties>());

    static readonly finishCancel = createAction(AppExamActionsEnum.FinishCancel);

    static readonly finishSuccess = createAction(AppExamActionsEnum.FinishSuccess, props<ContentExamMetadataSuccessProperties>());

    static readonly finishFailed = createAction(AppExamActionsEnum.FinishFailed, props<ContentExamFailedProperties>());

    static readonly clear = createAction(AppExamActionsEnum.Clear);

    static readonly assign = createAction(AppExamActionsEnum.Assign, props<ExamCreateOrUpdatePropertiesInterface>());

    static readonly assignSuccess = createAction(AppExamActionsEnum.AssignSuccess, props<ApiExamListInterface>());

    static readonly assignFailed = createAction(AppExamActionsEnum.AssignFailed, props<ExamCreateOrUpdateFailedPropertiesInterface>());

    static readonly clearBookExams = createAction(AppExamActionsEnum.ClearBookExams);

    static readonly fetchBookExams = createAction(AppExamActionsEnum.FetchBookExams, props<ExamActionsFetchExamsInterface>());

    static readonly fetchBookExamsSuccess = createAction(AppExamActionsEnum.FetchBookExamsSuccess, props<ApiExamsResponseInterface>());

    static readonly fetchBookExamsFailed = createAction(AppExamActionsEnum.FetchBookExamsFailed, props<ExamActionsFetchExamsFailedInterface>());

    static readonly fetchAssigned = createAction(AppExamActionsEnum.FetchAssigned, props<AppExamFetchAssignedProperties>());

    static readonly fetchAssignedSuccess = createAction(AppExamActionsEnum.FetchAssignedSuccess, props<ApiExamListInterface>());

    static readonly fetchAssignedFailed = createAction(AppExamActionsEnum.FetchAssignedFailed, props<AppExamFetchAssignedFailedProperties>());
}
