import {inject} from '@angular/core';
import {
    AppExamActions,
    AppExamFetchAssignedProperties,
    ContentExamPlanProperties,
    ContentExamProperties,
    ExamCreateOrUpdatePropertiesInterface,
    StepContentExamProperties,
} from '@component/exam/redux/exam.actions';
import {AppExamMetadata, AppExamStep} from '@component/exam/player/player.service';
import {AppExamSelectors} from '@component/exam/redux/exam.selectors';
import {AppExamActionsEnum} from '@component/exam/redux/exam.enum';
import {catchError, filter, map, mergeMap, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {ApiService} from '@core/services/api/api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {sprintf} from '@core/core.functions';
import {Store} from '@ngrx/store';
import {iif, of, OperatorFunction, throwError} from 'rxjs';
import {ApiExamListInterface, AppExamService} from '@component/exam/exam.service';
import {ExamActionsFetchExamsInterface} from '@store/exam';
import {HttpErrorResponse} from '@angular/common/http';

export class AppExamEffects {
    #actions: Actions = inject(Actions);

    #store: Store = inject(Store);

    #apiService: ApiService = inject(ApiService);

    #examService: AppExamService = inject(AppExamService);

    metadata$ = createEffect(() => this.#actions.pipe(
        ofType(AppExamActionsEnum.Metadata),
        withLatestFrom(this.#store.select(AppExamSelectors.selectMetadata)),
        filter((payload: [ContentExamProperties, AppExamMetadata | undefined]) => {
            const contentExam: AppExamMetadata | undefined = payload[1];

            return undefined === contentExam
                || contentExam.bookUuid !== payload[0].bookUuid
                || contentExam.documentDpsId !== payload[0].documentDpsId;
        }),
        mergeMap((payload: [ContentExamProperties, AppExamMetadata | undefined]) => this.#examService.fetchExamPlan(
            payload[0].bookUuid,
            payload[0].documentDpsId,
            payload[0].userId,
            payload[0].planId,
        ).pipe(
            map((metadata: AppExamMetadata) => AppExamActions.metadataSuccess({metadata})),
            catchError((errorResponse: HttpErrorResponse) => of(AppExamActions.metadataFailed({error: errorResponse.error, properties: payload[0]}))),
            takeUntil(this.#actions.pipe(ofType(AppExamActionsEnum.MetadataCancel))),
        )),
    ));

    step$ = createEffect(() => this.#actions.pipe(
        ofType(AppExamActionsEnum.Step),
        withLatestFrom(this.#store.select(AppExamSelectors.selectStep)),
        filter((payload: [StepContentExamProperties, AppExamStep | undefined]): boolean => {
            const contentExamAssignment: AppExamStep | undefined = payload[1];

            return undefined === contentExamAssignment
                || contentExamAssignment.bookUuid !== payload[0].bookUuid
                || contentExamAssignment.document.dpsId !== payload[0].documentDpsId;
        }),
        mergeMap((payload: [StepContentExamProperties, AppExamStep | undefined]) => this.#apiService
            .get<AppExamStep>(sprintf(
                '/api/v2/exams/%s/player/%s/plan/%d/assignment/%s/attempt/%d',
                payload[0].bookUuid,
                payload[0].documentDpsId,
                payload[0].examPlanId,
                payload[0].assignmentDpsId,
                payload[0].attempt,
            ), undefined !== payload[0].userId ? new URLSearchParams([['userId', payload[0].userId.toString()]]) : undefined).pipe(
                map((step: AppExamStep) => AppExamActions.stepSuccess({step})),
                catchError((errorResponse: HttpErrorResponse) => of(AppExamActions.stepFailed({error: errorResponse.error, properties: payload[0]}))),
                takeUntil(this.#actions.pipe(ofType(AppExamActionsEnum.StepCancel))),
            ),
        ),
    ));

    start$ = createEffect(() => this.#actions.pipe(
        ofType(AppExamActionsEnum.Start),
        withLatestFrom(this.#store.select(AppExamSelectors.selectMetadata)),
        mergeMap((payload: [ContentExamPlanProperties, AppExamMetadata | undefined]) => iif(
            (): boolean => undefined === payload[1] || payload[1].inProgress,
            throwError(new Error(`Unable to start exam, ${undefined === payload[1] ? 'metadata is undefined' : 'already in progress'}`)),
            of(payload),
        )) as OperatorFunction<[ContentExamPlanProperties, AppExamMetadata | undefined], [ContentExamPlanProperties, AppExamMetadata]>,
        mergeMap((payload: [ContentExamPlanProperties, AppExamMetadata]) => this.#apiService.get<AppExamMetadata>(sprintf(
            '/api/v2/exams/%s/player/%s/plan/%d/start',
            payload[0].bookUuid,
            payload[0].documentDpsId,
            payload[0].examPlanId,
        )).pipe(
            map((metadata: AppExamMetadata) => AppExamActions.startSuccess({metadata})),
            catchError((errorResponse: HttpErrorResponse) => of(AppExamActions.startFailed({error: errorResponse.error, properties: payload[0]}))),
            takeUntil(this.#actions.pipe(ofType(AppExamActionsEnum.StartCancel))),
        )),
    ));

    finish$ = createEffect(() => this.#actions.pipe(
        ofType(AppExamActionsEnum.Finish),
        withLatestFrom(this.#store.select(AppExamSelectors.selectMetadata)),
        mergeMap((payload: [ContentExamPlanProperties, AppExamMetadata | undefined]) => iif(
            (): boolean => undefined !== payload[1] && payload[1].inProgress,
            of(payload),
            throwError(new Error(`Unable to finish exam, ${undefined === payload[1] ? 'metadata is undefined' : 'exam has already been finished'}`)),
        )) as OperatorFunction<[ContentExamPlanProperties, AppExamMetadata | undefined], [ContentExamPlanProperties, AppExamMetadata]>,
        mergeMap((payload: [ContentExamPlanProperties, AppExamMetadata]) => this.#apiService.get<AppExamMetadata>(sprintf(
            '/api/v2/exams/%s/player/%s/plan/%s/finish',
            payload[0].bookUuid,
            payload[0].documentDpsId,
            payload[0].examPlanId,
        )).pipe(
            map((metadata: AppExamMetadata) => AppExamActions.finishSuccess({metadata})),
            catchError((errorResponse: HttpErrorResponse) => of(AppExamActions.finishFailed({error: errorResponse.error, properties: payload[0]}))),
            takeUntil(this.#actions.pipe(ofType(AppExamActionsEnum.FinishCancel))),
        )),
    ));

    assignExam$ = createEffect(() => this.#actions.pipe(
        ofType(AppExamActionsEnum.Assign),
        switchMap((properties: ExamCreateOrUpdatePropertiesInterface) => this.#examService.assign(properties.mode, properties.bookUuid, properties.exam)
            .pipe(
                map(response => AppExamActions.assignSuccess(response)),
                catchError((errorResponse: HttpErrorResponse) => of(AppExamActions.assignFailed({error: errorResponse.error, ...properties}))),
            )),
    ));

    fetchBookExams$ = createEffect(() => this.#actions.pipe(
        ofType(AppExamActionsEnum.FetchBookExams),
        switchMap((properties: ExamActionsFetchExamsInterface) => this.#examService.fetchByBookUuid(properties.bookUuid, properties.types)
            .pipe(
                map(response => AppExamActions.fetchBookExamsSuccess(response)),
                catchError((errorResponse: HttpErrorResponse) => of(AppExamActions.fetchBookExamsFailed({error: errorResponse.error, ...properties}))),
            )),
    ));

    fetchAssigned$ = createEffect(() => this.#actions.pipe(
        ofType(AppExamActionsEnum.FetchAssigned),
        switchMap((properties: AppExamFetchAssignedProperties) => this.#examService.fetchAssigned(properties.types, properties.offset, properties.year)
            .pipe(
                map(response => AppExamActions.fetchAssignedSuccess(response)),
                catchError((errorResponse: HttpErrorResponse) => of(AppExamActions.fetchAssignedFailed({error: errorResponse.error, ...properties}))),
            )),
    ));
}
