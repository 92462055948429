export enum ApiV2EndpointsEnum {
    LibraryAlgebrakitNewSession = '/api/v2/algebrakit/%s/new',
    LibraryAlgebrakitFinishSession = '/api/v2/algebrakit/%s/finish',
    LibraryDrillsterSessionUpdateScore = '/api/v2/drillster/%s/session/%s/update',
    LibraryDrillsterSession = '/api/v2/drillster/%s/version/%s/session',
    LibraryBooks = '/api/v2/library/books',
    LibraryBook = '/api/v2/library/books/%s', // :bookUuid
    SearchBooks = '/api/v2/search/books',
    SearchBook = '/api/v2/search/books/%s', // :bookUuid
    LibraryBookPatchIndex = '/api/v2/library/books/%s/index', // :bookUuid
    LibraryBookChapters = '/api/v2/library/books/%s/chapters', // :bookUuid
    LibraryBookChapter = '/api/v2/library/books/%s/chapters/%s', // :bookUuid, :chapterUuid
    LibraryBookChapterToc = '/api/v2/library/books/%s/chapters/%s/table-of-contents', // :bookUuid, :chapterUuid,
    LibraryBookChapterDocument = '/api/v2/library/books/%s/chapters/%s/documents/%s', //:bookUuid, :chapterUuid, :documentDpsId
    LibraryBookChapterDocumentProgress = '/api/v2/library/books/%s/chapters/%s/documents/%s/user/%s', //:bookUuid, :chapterUuid, :documentDpsId, :userId
    LibraryBookChapterDocumentByAlfrescoId = '/api/v2/library/documents/%s', //:alfrescoId
    DocumentSources = '/api/v2/document/%s/sources', //:bookUuid
    DocumentSourcesTeacherMaterial = '/api/v2/document/%s/sources/teacher', //:bookUuid
    DocumentSourcesBook = '/api/v2/document/:bookId/sources/document/:dpsId', // :bookId, :dpsId,
    DocumentSourceCreate = '/api/v2/document/%s/sources', // :bookUuid
    DocumentSourceRemove = '/api/v2/document/%s/sources', // :bookUuid
    LicenseActivate = '/api/v2/license/books/activate',
    SecurityCurrentUser = '/api/v2/security/users/current',
    SecurityUpgradeToTeacher = '/api/v2/security/users/upgrade/role',
    Groups = '/api/v2/group',
    GroupsByBookUuid = '/api/v2/group/book/%s',
    GroupsOrganization = '/api/v2/group/organization',
    GroupsOrganizationAndBook = '/api/v2/group/organization/%s',
    GroupCreate = '/api/v2/group',
    GroupRenewCode = '/api/v2/group/%s/code', // groupId
    Group = '/api/v2/group/%s', // groupId
    GroupStatusUpdate = '/api/v2/group/%s/active-state', // :groupId
    GroupJoin = '/api/v2/group/join',
    GroupJoinOrConnect = '/api/v2/group/join/%s',
    GroupUserRemove = '/api/v2/group/%s/user/%s', // :groupId, :userId
    GeneralPublisher = '/api/v2/publisher/current',
    GeneralPatchUserSetting = '/api/v2/user/setting/%s', // :settingType
    AssignmentSaveAnswer = '/api/v2/assignment/%s/field/%s',
    AssignmentAnswers = '/api/v2/assignments/%s/answers/%d', // :assignmentDocumentDpsId, :userId
    AssignmentAnswersField = '/api/v2/assignments/%s/answers/%s', // :assignmentDocumentDpsId
    DownloadAttachment = 'api/v2/assignments/%s/attachment/%s', // :assignmentDocumentDpsId, :attachmentId
    Attachment = 'api/v2/assignments/%s/attachment', // :assignmentDocumentDpsId,
    AssignmentHandIn = '/api/v2/assignments/%s', // :assignmentDocumentDpsId
    AssignmentReview = '/api/v2/assignments/%s/review', // :assignmentDocumentDpsId
    AssignmentAutoCheck = '/api/v2/assignments/%s/auto-check', // :assignmentDocumentDpsId
    Profile = '/api/v2/profile/',
    SecurityCurrentUserAcceptTerms = '/api/v2/security/users/terms',
    ExamStartRemindo = '/api/v2/library/books/%s/chapters/%s/exams/%s/start', // :bookUuid, :chapterUuid, examUuid
    ProgressUserProgression = '/api/v2/progress/%s/progression', // :bookUuid
    ProgressUser = '/api/v2/progress/%s/chapter/%s/user/%s', // :bookUuid, :chapterUuid, :userId, :chapterUuid
    ProgressGroup = '/api/v2/progress/%s/chapter/%s/group/%s', // :bookUuid, :chapterUuid, :groupId
    ProgressReview = '/api/v2/progress/%s/chapter/%s/group/%s/user/%s/review/%s', // :bookUuid, :chapterUuid, :groupId, :userId, :assignmentDocumentDpsId
    ProgressUpdatedReview = '/api/v2/progress/%s/chapter/%s/group/%s/user/%s/review/%s/update', // :bookUuid, :chapterUuid, :groupId, :userId, :assignmentDocumentDpsId
    ProgressRemoveAnswers = '/api/v2/progress/%s/chapter/%s/group/%s/user/%s/answers/%s', // :bookUuid, :chapterUuid, :groupId, :userId, :assignmentDocumentDpsId
    SelfExamineCreate = '/api/v2/library/self-examine/%s/group/%s', // :bookUuid, :groupId
    OrganizationList = '/api/v2/organization',
    OrganizationAddCurrentUser = '/api/v2/organization/%s/current-user', // :organizationId
    requestOrganization = '/api/v2/organization/request',
    Messages = '/api/v2/messages',
    MessageMarkRead = '/api/v2/messages/mark-as-read',
    MessageMarkAllRead = '/api/v2/messages/mark-all-as-read',
    MessagesDelete = '/api/v2/messages',
    TermsTrain = '/api/v2/terms/train',
    FeedbackCreate = '/api/v2/feedback/book/:bookUuid/chapter/:chapterUuid/assignment/:assignmentDocumentDpsId/field/:fieldNumber/user/:userId',
    FeedbackRespond = '/api/v2/feedback/book/:bookUuid/chapter/:chapterUuid/respond/:feedbackId',
}
