import {Component, HostBinding, Input, OnDestroy} from '@angular/core';
import {MemoizedSelector, Store} from '@ngrx/store';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {GroupSelectors} from '@store/group';
import {AbstractSelect} from '@shared/abstract/select/abstract.select';
import {SelectionActions, SelectionSelectors} from '@store/selection';
import {GroupInterface} from '@shared/interfaces/group.interface';

export enum GroupMenuPlaceHolder {
    All = 'groupMenu.all',
    SelectOne = 'groupMenu.selectOne',
}
@Component({
    selector: 'app-group-select',
    templateUrl: './group-select.component.html',
    styleUrls: ['./group-select.component.scss'],
})
export class GroupSelectComponent extends AbstractSelect<GroupInterface> implements OnDestroy {
    public buttonType = ButtonTypeEnum;

    @HostBinding('class')
    public readonly classes: string[] = ['group-select'];

    @Input()
    public placeHolder: GroupMenuPlaceHolder = GroupMenuPlaceHolder.All;

    public constructor(store: Store) {
        super(store);
    }

    public handleClick(group?: GroupInterface): void {
        this.dispatch(SelectionActions.setGroup({item: group}));
    }

    protected getItemSelector(): MemoizedSelector<object, GroupInterface | undefined> {
        return SelectionSelectors.selectGroup;
    }

    protected getItemsSelector(): MemoizedSelector<object, GroupInterface[] | undefined> {
        return GroupSelectors.selectGroupsAsArray;
    }
}
