import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {of, OperatorFunction} from 'rxjs';
import {DocumentAnswersService} from '@shared/modules/content/services/answers/answers.service';
import {AssignmentActionsEnum} from '@store/assignment/assignment.enum';
import {AssignmentActions} from '@store/assignment/assignment.actions';
import {
    AssignmentAnswerPropertiesInterface,
    AssignmentFeedbackCreatePropertiesInterface,
    AssignmentFeedbackRespondPropertiesInterface,
    AssignmentPropertiesInterface,
    AssignmentReviewPropertiesInterface,
    AssignmentUserPropertiesInterface,
} from '@store/assignment/assignment.properties';
import {BookApiService} from '@core/services/book/book-api.service';
import {AssignmentSelectors} from '@store/assignment/assignment.selectors';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {AssignmentDocumentDetailsInterface} from '@core/services/assignment/assignment.service';
import {AssignmentContextInterface, AssignmentsInterface} from '@store/assignment/assignment.reducer';
import {FeedbackService} from '@component/feedback/feedback.service';

@Injectable()
export class AssignmentEffects {
    public constructor(
        private store: Store,
        private actions$: Actions,
        private bookService: BookApiService,
        private documentAnswersService: DocumentAnswersService,
        private feedbackService: FeedbackService,
    ) {
    }

    public fetchByDpsId$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.FetchByAssignmentContext),
        withLatestFrom(this.store.select(AssignmentSelectors.selectAssignments)),
        filter((payload: [AssignmentContextInterface, AssignmentsInterface]) => !payload[1].hasOwnProperty(payload[0].documentDpsId)),
        mergeMap(payload => this.bookService
            .retrieveDocument(payload[0].bookUuid, payload[0].chapterUuid, payload[0].documentDpsId)
            .pipe(
                filter(document => undefined !== document.assignment) as OperatorFunction<DocumentDetailsInterface, AssignmentDocumentDetailsInterface>,
                map(document => AssignmentActions.fetchByAssignmentContextSuccess({document})),
                catchError(error => of(AssignmentActions.fetchByAssignmentContextFailed({error, properties: payload[0]}))),
            )),
    ));

    public saveAnswer$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.SaveAnswer),
        switchMap((properties: AssignmentAnswerPropertiesInterface<any>) => this.documentAnswersService
            .saveAnswer(
                properties.assignmentAnswer.assignmentDocumentDpsId,
                properties.assignmentAnswer.fieldId,
                properties.assignmentAnswer.value,
            ).pipe(
                map(() => AssignmentActions.saveAnswerSuccess(properties)),
                catchError(error => of(AssignmentActions.saveAnswerFailed({error, ...properties}))),
            )),
    ));

    public removeAnswer$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.RemoveAnswers),
        switchMap((properties: AssignmentUserPropertiesInterface) => this.documentAnswersService
            .removeAnswers(properties.assignment.dpsId, properties.user.id)
            .pipe(
                map(assignment => AssignmentActions.removeAnswersSuccess(assignment)),
                catchError(error => of(AssignmentActions.removeAnswersFailed({error, properties}))),
            )),
    ));

    public handIn$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.HandIn),
        switchMap((properties: AssignmentPropertiesInterface) => this.documentAnswersService
            .handIn(properties.assignment.dpsId)
            .pipe(
                map(assignment => AssignmentActions.handInSuccess(assignment)),
                catchError(error => of(AssignmentActions.handInFailed({error, properties}))),
            )),
    ));

    public autoCheck$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.AutoCheck),
        switchMap((properties: AssignmentPropertiesInterface) => this.documentAnswersService
            .autoCheck(properties.assignment.dpsId)
            .pipe(
                map(assignment => AssignmentActions.autoCheckSuccess(assignment)),
                catchError(error => of(AssignmentActions.autoCheckFailed({error, properties}))),
            )),
    ));

    public saveReview$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.SaveReview),
        switchMap((properties: AssignmentReviewPropertiesInterface) => this.documentAnswersService
            .saveReview(properties.assignment.dpsId, properties.review)
            .pipe(
                map(assignment => AssignmentActions.saveReviewSuccess(assignment)),
                catchError(error => of(AssignmentActions.saveReviewFailed({error, properties}))),
            )),
    ));

    public createFeedback$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.CreateFeedback),
        switchMap((properties: AssignmentFeedbackCreatePropertiesInterface) => this.feedbackService
            .createFeedback(properties)
            .pipe(
                map(document => AssignmentActions.createFeedbackSuccess({document})),
                catchError(error => of(AssignmentActions.createFeedbackFailed({error, properties}))),
            )),
    ));

    public respondFeedback$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentActionsEnum.RespondFeedback),
        switchMap((properties: AssignmentFeedbackRespondPropertiesInterface) => this.feedbackService
            .feedbackRespond(properties)
            .pipe(
                map(document => AssignmentActions.respondFeedbackSuccess({document})),
                catchError(error => of(AssignmentActions.respondFeedbackFailed({error, properties}))),
            )),
    ));
}
