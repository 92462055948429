import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {Subscription} from 'rxjs';
import {UserInterface} from '@shared/interfaces/user.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {RoleEnum} from '@core/security/enum/role.enum';
import {BreadcrumbActions} from '@store/breadcrumb';
import {BreadCrumbInterface} from '@shared/interfaces/breadcrumb.interface';
import {MessageSelectors} from '@store/message/message.selectors';
import {MessageInterface, MessagesInterface} from '@shared/interfaces/message.interface';
import {MessageActions} from '@store/message/message.actions';
import {MessageDeleteDialogComponent} from '@/component/messages/message-delete-dialog/message-delete-dialog.component';
import {MessageDialogComponent} from '@/component/messages/message-dialog/message-dialog.component';
import {HeaderTitleService} from '@component/header-title/header-title.service';

export enum TypeEnum {
    Read = 0,
    UnRead = 1
}

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {
    #subscriptions: Subscription[] = [];

    public readonly buttonType = ButtonTypeEnum;

    public messages: MessageInterface[] | undefined;

    public user: UserInterface | undefined;

    public bookUuid!: string;

    public total: number = 0;

    public unread: number = 0;

    public bookMethodColor?: string;

    public loading: boolean = true;

    public innerWidth: number = 0;

    public currentPage: number = 1;

    public isMobile: boolean = false;

    public itemsPerPage: number = 15;

    public fileSelection: string[] = [];

    public moduleSelection: string[] = [];

    public isRoleHO: boolean;

    public readonly TypeEnum = TypeEnum;

    public checkboxSelection: number[] = [];

    public toggleStatus: boolean = false;

    #translator: TranslateService;

    public ngOnDestroy(): void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
        this.store.dispatch(BreadcrumbActions.unsetBreadcrumbsAction());
    }

    public constructor(
        private activatedRoute: ActivatedRoute,
        private authorizationService: AuthorizationService,
        private dialog: MatDialog,
        private route: Router,
        private store: Store,
        private translateService: TranslateService,
        private headerTitleService: HeaderTitleService,
        translator: TranslateService,
    ) {
        this.isRoleHO = this.authorizationService.isGranted(RoleEnum.RoleOrganizationHo);
        this.#translator = translator;
    }

    ngOnInit(): void {
        this.#subscriptions.push(
            this.store.select(MessageSelectors.selectMessages).subscribe(messages => this.#handleSelectMessages(messages)),
        );
    }

    dialogDeleteToggle(ids: number[]) {
        this.dialog.open(MessageDeleteDialogComponent, {
            panelClass: `custom-dialog-container`,
            data: {
                ids: ids,
                total: ids.length,
                offset: this.currentPage - 1,
                limit: this.itemsPerPage,
            },
        });
    }

    markToggle(ids: number[], type: TypeEnum) {
        const isRead = type === TypeEnum.Read;

        this.store.dispatch(MessageActions.markMessage({ids, isRead, offset: this.currentPage - 1, limit: this.itemsPerPage}));
        this.toggleStatus = false;
    }

    handlePageChange(selectedPage: number): void {
        if (selectedPage === this.currentPage) {
            return;
        }

        this.currentPage = selectedPage;
        const offset: number = (selectedPage - 1) * this.itemsPerPage;

        this.#fetchMessages(offset);
    }

    dialogToggle(message: MessageInterface) {
        const messageClone : MessageInterface = {...message};

        if ('new_exam' === message.type) {
            const body = this.#translator.instant('exam.snackbar.newExamHere');
            const title = this.#translator.instant('exam.snackbar.newExam', {exam: message.title});

            messageClone.body = '<a href="/exam" class="text-primary">' + body + '</a>';
            messageClone.title = title;
        }

        if ('new_feedback' === message.type) {
            const parsedBody = JSON.parse(typeof messageClone.body === 'string' ? messageClone.body : '');

            messageClone.body = this.translateService.instant(parsedBody.bodyTranslateTag, parsedBody);
            messageClone.title = this.translateService.instant(typeof messageClone.title === 'string' ? messageClone.title : '');
        }

        this.dialog.open(MessageDialogComponent, {panelClass: `custom-dialog-container`, data: {message: messageClone}});
    }

    selectionCheckboxChecked(id: number) {
        return this.checkboxSelection.indexOf(id) !== -1;
    }

    selectionCheckbox(id: number): void {
        if (this.checkboxSelection.indexOf(id) === -1) {
            this.checkboxSelection.push(id);
        } else {
            this.checkboxSelection.splice(this.checkboxSelection.indexOf(id), 1);
        }
    }

    selectionAllCheckbox(completed: boolean): void {
        this.toggleStatus = completed;
        this.checkboxSelection = [];

        if (this.messages === undefined) {
            return;
        }

        if (this.toggleStatus) {
            for (let i = 0; i <= this.messages.length; i++) {
                if (undefined !== this.messages[i]) {
                    this.checkboxSelection.push(this.messages[i].id);
                }
            }
        } else {
            this.checkboxSelection = [];
        }
    }

    #handleSelectMessages(messages: MessagesInterface | undefined): void {
        if (undefined === messages) {
            return;
        }

        const breadcrumbInterface: BreadCrumbInterface[] = [
            {'label': this.translateService.instant('navigation.messages'), 'url': '/books/'},
        ];

        this.headerTitleService.setHeaderTitle(this.translateService.instant('navigation.messages'));
        this.store.dispatch(BreadcrumbActions.setBreadcrumbsAction({breadcrumbs: breadcrumbInterface}));

        this.messages = messages?.messages;
        this.total = messages.total;
        this.unread = messages.unread;
        this.loading = false;
    }

    #fetchMessages(offset: number = 0): void {
        this.store.dispatch(MessageActions.fetchMessages({offset, limit: this.itemsPerPage}));
        this.checkboxSelection = [];
    }
}
