import {createAction, props} from '@ngrx/store';
import {BookDetailInterface, BookListInterface} from '@shared/interfaces/book.interface';
import {ChapterProgressInterface} from '@shared/interfaces/chapter.interface';
import {LibraryActionsEnum} from '@store/library';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {
    AlgebrakitSessionFailedPropertiesInterface,
    AlgebrakitSessionFinishSuccessPropertiesInterface,
    AlgebrakitSessionPropertiesInterface,
    DrillsterSessionFailedPropertiesInterface,
    DrillsterSessionSuccessPropertiesInterface,
    LibraryAddSourceSuccessPropertiesInterface,
    LibraryBookPatchIndexPropertiesInterface,
    LibraryBookPropertiesInterface,
    LibraryBookSearchPropertiesInterface,
    LibraryChapterPropertiesInterface,
    LibraryDocumentProgressPropertiesInterface,
    LibraryDocumentPropertiesFailedInterface,
    LibraryDocumentPropertiesInterface,
    LibrarySourceAddPropertiesInterface,
    LibrarySourceFailedPropertiesInterface,
    LibrarySourceFileFailedPropertiesInterface,
    LibrarySourceFilePropertiesInterface,
    LibrarySourceFileSuccessPropertiesInterface,
    LibrarySourcePropertiesInterface,
    LibrarySourceRemoveInterface,
} from '@store/library/library.properties';
import {DocumentSourceDataInterface, TeacherSourceDataInterface} from '@core/services/source/source.service';
import {AssignmentDocumentDetailsInterface} from '@core/services/assignment/assignment.service';
import {PaginationInterface} from '@core/interfaces/pagination.interface';
import {SearchPropertiesFailedInterface, SearchPropertiesInterface} from '@store/search/search.properties';

export class LibraryActions {
    // Books
    static readonly unsetBook = createAction(LibraryActionsEnum.unsetBook);

    static readonly fetchBooks = createAction(LibraryActionsEnum.fetchBooks, props<PaginationInterface>());

    static readonly fetchBooksSuccess = createAction(LibraryActionsEnum.fetchBooksSuccess, props<BookListInterface>());

    // Books search
    static readonly fetchBooksSearchResults = createAction(LibraryActionsEnum.fetchBooksSearchResults, props<SearchPropertiesInterface>());

    static readonly fetchBooksSearchResultsFailed = createAction(LibraryActionsEnum.fetchBooksSearchResultsFailed, props<SearchPropertiesFailedInterface>());

    // Book
    static readonly fetchBook = createAction(LibraryActionsEnum.fetchBook, props<LibraryBookPropertiesInterface>());

    static readonly fetchBookCancel = createAction(LibraryActionsEnum.fetchBookCancel);

    static readonly fetchBookSuccess = createAction(LibraryActionsEnum.fetchBookSuccess, props<BookDetailInterface>());

    // Book search
    static readonly fetchBookSearchResults = createAction(LibraryActionsEnum.fetchBookSearchResults, props<LibraryBookSearchPropertiesInterface>());

    static readonly fetchBookSearchResultsFailed = createAction(LibraryActionsEnum.fetchBookSearchResultsFailed, props<SearchPropertiesFailedInterface>());

    // Book patching

    static readonly patchBookIndex = createAction(LibraryActionsEnum.patchBookIndex, props<LibraryBookPatchIndexPropertiesInterface>());

    static readonly patchBookIndexCancel = createAction(LibraryActionsEnum.patchBookIndexCancel);

    static readonly patchBookIndexSuccess = createAction(LibraryActionsEnum.patchBookIndexSuccess, props<BookListInterface>());

    // Chapters
    static readonly unsetChapter = createAction(LibraryActionsEnum.unsetChapter);

    static readonly fetchChapters = createAction(LibraryActionsEnum.fetchChapters, props<LibraryBookPropertiesInterface>());

    static readonly fetchChaptersCancel = createAction(LibraryActionsEnum.fetchChaptersCancel);

    static readonly fetchChaptersSuccess = createAction(LibraryActionsEnum.fetchChaptersSuccess, props<{ chapters: ChapterProgressInterface[] }>());

    static readonly fetchChapter = createAction(LibraryActionsEnum.fetchChapter, props<LibraryChapterPropertiesInterface>());

    static readonly fetchChapterCancel = createAction(LibraryActionsEnum.fetchChapterCancel);

    static readonly fetchChapterSuccess = createAction(LibraryActionsEnum.fetchChapterSuccess, props<{ chapter: ChapterProgressInterface }>());

    // Documents
    static readonly fetchDocument = createAction(LibraryActionsEnum.fetchDocument, props<LibraryDocumentPropertiesInterface>());

    static readonly fetchDocumentCancel = createAction(LibraryActionsEnum.fetchBookCancel);

    static readonly fetchDocumentSuccess = createAction(LibraryActionsEnum.fetchDocumentSuccess, props<{ document: DocumentDetailsInterface }>());

    /**
     * Documents for progress
     */
    static readonly fetchDocumentProgress = createAction(LibraryActionsEnum.fetchDocumentProgress, props<LibraryDocumentProgressPropertiesInterface>());

    static readonly fetchDocumentProgressSuccess = createAction(LibraryActionsEnum.fetchDocumentProgressSuccess, props<{ document: AssignmentDocumentDetailsInterface }>());

    static readonly fetchDocumentProgressFailed = createAction(LibraryActionsEnum.fetchDocumentProgressFailed, props<LibraryDocumentPropertiesFailedInterface>());

    // Algebrakit
    static readonly finishAlgebrakitSession = createAction(LibraryActionsEnum.algebrakitFinishSession, props<AlgebrakitSessionPropertiesInterface>());

    static readonly finishAlgebrakitSessionSuccess = createAction(LibraryActionsEnum.algebrakitFinishSessionSuccess, props<AlgebrakitSessionFinishSuccessPropertiesInterface>());

    static readonly finishAlgebrakitSessionFailed = createAction(LibraryActionsEnum.algebrakitFinishSessionFailed, props<AlgebrakitSessionFailedPropertiesInterface>());

    static readonly newAlgebrakitSession = createAction(LibraryActionsEnum.algebrakitNewSession, props<AlgebrakitSessionPropertiesInterface>());

    static readonly newAlgebrakitSessionSuccess = createAction(LibraryActionsEnum.algebrakitNewSessionSuccess, props<AlgebrakitSessionPropertiesInterface>());

    static readonly newAlgebrakitSessionFailed = createAction(LibraryActionsEnum.algebrakitNewSessionFailed, props<AlgebrakitSessionFailedPropertiesInterface>());

    // Drillster
    static readonly updateDrillsterSessionScore = createAction(LibraryActionsEnum.updateDrillsterSessionScore, props<{ code: string, id: number, score: number }>());

    static readonly updateDrillsterSessionScoreSuccess = createAction(LibraryActionsEnum.updateDrillsterSessionScoreSuccess, props<DrillsterSessionSuccessPropertiesInterface>());

    static readonly updateDrillsterSessionScoreFailed = createAction(LibraryActionsEnum.updateDrillsterSessionScoreFailed, props<DrillsterSessionFailedPropertiesInterface>());

    static readonly fetchDrillsterSession = createAction(LibraryActionsEnum.fetchDrillsterSession, props<{ code: string, documentVersionId: number }>());

    static readonly fetchDrillsterSessionSuccess = createAction(LibraryActionsEnum.fetchDrillsterSessionSuccess, props<DrillsterSessionSuccessPropertiesInterface>());

    static readonly fetchDrillsterSessionFailed = createAction(LibraryActionsEnum.fetchDrillsterSessionFailed, props<DrillsterSessionFailedPropertiesInterface>());

    // Sources
    static readonly unsetSources = createAction(LibraryActionsEnum.unsetSources);

    static readonly fetchSources = createAction(LibraryActionsEnum.fetchSources, props<LibrarySourcePropertiesInterface>());

    static readonly fetchSourcesSuccess = createAction(LibraryActionsEnum.fetchSourcesSuccess, props<DocumentSourceDataInterface>());

    static readonly fetchSourcesFailed = createAction(LibraryActionsEnum.fetchSourcesFailed, props<LibrarySourceFailedPropertiesInterface>());

    static readonly addSource = createAction(LibraryActionsEnum.addSource, props<LibrarySourceAddPropertiesInterface>());

    static readonly addSourceSuccess = createAction(LibraryActionsEnum.addSourceSuccess, props<LibraryAddSourceSuccessPropertiesInterface>());

    static readonly addSourceFailed = createAction(LibraryActionsEnum.addSourceFailed, props<LibrarySourceFailedPropertiesInterface>());

    static readonly addSourceFile = createAction(LibraryActionsEnum.addSourceFile, props<LibrarySourceFilePropertiesInterface>());

    static readonly addSourceFileSuccess = createAction(LibraryActionsEnum.addSourceFileSuccess, props<LibrarySourceFileSuccessPropertiesInterface>());

    static readonly addSourceFileFailed = createAction(LibraryActionsEnum.addSourceFileFailed, props<LibrarySourceFileFailedPropertiesInterface>());

    static readonly removeSource = createAction(LibraryActionsEnum.removeSource, props<LibrarySourceRemoveInterface>());

    static readonly removeSourceSuccess = createAction(LibraryActionsEnum.removeSourceSuccess);

    static readonly removeSourceFailed = createAction(LibraryActionsEnum.removeSourceFailed, props<LibrarySourceFileFailedPropertiesInterface>());

    // Sources Teacher Materials
    static readonly unsetSourcesTeacherMaterials = createAction(LibraryActionsEnum.unsetSourcesTeacherMaterials);

    static readonly fetchSourcesTeacherMaterials = createAction(LibraryActionsEnum.fetchSourcesTeacherMaterials, props<LibrarySourcePropertiesInterface>());

    static readonly fetchSourcesTeacherMaterialsSuccess = createAction(LibraryActionsEnum.fetchSourcesTeacherMaterialsSuccess, props<TeacherSourceDataInterface>());

    static readonly fetchSourcesTeacherMaterialsFailed = createAction(LibraryActionsEnum.fetchSourcesTeacherMaterialsFailed, props<LibrarySourceFailedPropertiesInterface>());
}
