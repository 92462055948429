import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {BookApiService} from '@core/services/book/book-api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {BookDetailInterface, BookListInterface} from '@shared/interfaces/book.interface';
import {LibraryActions, LibraryActionsEnum, LibrarySelectors} from '@store/library';
import {
    AlgebrakitSessionPropertiesInterface,
    DrillsterSessionPropertiesInterface,
    LibraryBookPatchIndexPropertiesInterface,
    LibraryBookPropertiesInterface,
    LibraryBookSearchPropertiesInterface,
    LibraryChapterPropertiesInterface,
    LibraryDocumentProgressPropertiesInterface,
    LibraryDocumentPropertiesInterface,
    LibrarySourcePropertiesInterface,
} from '@store/library/library.properties';
import {FileResponseInterface, SourceService} from '@core/services/source/source.service';
import {SearchActions} from '@store/search';
import {DocumentService} from '@core/services/document/document.service';
import {DrillsterService} from '@core/services/drillster/drillster.service';
import {LicenseActions} from '@store/license';
import {SecurityActions} from '@store/security';
import {PaginationInterface} from '@core/interfaces/pagination.interface';
import {SearchPropertiesInterface} from '@store/search/search.properties';
import {of} from 'rxjs';
import {ChapterInterface} from '@shared/interfaces/chapter.interface';

@Injectable()
export class LibraryEffects {
    public constructor(
        private store: Store,
        private actions$: Actions,
        private bookService: BookApiService,
        private sourceService: SourceService,
        private documentService: DocumentService, // Load service, so it'll listen to documents
        private drillsterService: DrillsterService,
    ) {
    }

    public fetchBooks$ = createEffect(() => this.actions$.pipe(
        ofType(LibraryActionsEnum.fetchBooks, LicenseActions.processLicensesSuccess, SecurityActions.upgradeToTeacherActionSuccess),
        mergeMap((properties: PaginationInterface) => this.bookService.retrieveBooks(properties.offset, properties.limit, properties.query, properties.withoutProgress).pipe(
            map((bookList: BookListInterface) => LibraryActions.fetchBooksSuccess(bookList))),
        ),
    ));

    public fetchBooksSearchResults$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActions.fetchBooksSearchResults),
            switchMap((properties: SearchPropertiesInterface) => {
                return this.bookService
                    .retrieveBooksSearchResults(properties.query, properties.offset, properties.limit)
                    .pipe(
                        map(results => SearchActions.fetchResultsSuccess(results)),
                        catchError(error => of(LibraryActions.fetchBooksSearchResultsFailed({error, ...properties}))),
                    );
            }),
        );
    });

    public fetchBook$ = createEffect(() => this.actions$.pipe(
        ofType(LibraryActionsEnum.fetchBook),
        withLatestFrom(this.store.select(LibrarySelectors.selectBook)),
        filter((payload: [LibraryBookPropertiesInterface, BookDetailInterface | undefined]) => payload[1]?.uuid !== payload[0].bookUuid),
        mergeMap(payload => this.bookService.retrieveBook(payload[0].bookUuid).pipe(
            map(book => LibraryActions.fetchBookSuccess(book)),
            takeUntil(this.actions$.pipe(
                ofType(LibraryActions.fetchBookCancel),
            )),
        )),
    ));

    public patchBookIndex$ = createEffect(() => this.actions$.pipe(
        ofType(LibraryActionsEnum.patchBookIndex),
        mergeMap((properties: LibraryBookPatchIndexPropertiesInterface) => this.bookService.patchBookIndex(properties.bookUuid, properties.data, properties.offset, properties.limit).pipe(
            map((bookList: BookListInterface) => LibraryActions.patchBookIndexSuccess(bookList)),
            takeUntil(this.actions$.pipe(
                ofType(LibraryActions.patchBookIndexCancel),
            )),
        )),
    ));

    public fetchBookSearchResults$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActions.fetchBookSearchResults),
            switchMap((properties: LibraryBookSearchPropertiesInterface) => {
                return this.bookService
                    .retrieveBookSearchResults(properties.bookUuid, properties.query, properties.offset, properties.limit)
                    .pipe(
                        map(results => SearchActions.fetchResultsSuccess(results)),
                        catchError(error => of(LibraryActions.fetchBookSearchResultsFailed({error, ...properties}))),
                    );
            }),
        );
    });

    public fetchChapter$ = createEffect(() => this.actions$.pipe(
        ofType(LibraryActionsEnum.fetchChapter),
        withLatestFrom(this.store.select(LibrarySelectors.selectChapter)),
        filter((payload: [LibraryChapterPropertiesInterface, ChapterInterface | undefined]): boolean => payload[1]?.uuid !== payload[0].chapterUuid),
        mergeMap(payload => this.bookService.retrieveChapter(payload[0].bookUuid, payload[0].chapterUuid).pipe(
            map(chapter => LibraryActions.fetchChapterSuccess({chapter})),
            takeUntil(this.actions$.pipe(
                ofType(LibraryActions.fetchChapterCancel),
            )),
        )),
    ));

    public fetchChapters$ = createEffect(() => this.actions$.pipe(
        ofType(LibraryActionsEnum.fetchChapters),
        mergeMap((props: LibraryBookPropertiesInterface) => this.bookService.retrieveChapters(props.bookUuid).pipe(
            map(chapters => LibraryActions.fetchChaptersSuccess({chapters})),
            takeUntil(this.actions$.pipe(
                ofType(LibraryActions.fetchChaptersCancel),
            )),
        )),
    ));

    public fetchDocument$ = createEffect(() => this.actions$.pipe(
        ofType(LibraryActionsEnum.fetchDocument),
        mergeMap((props: LibraryDocumentPropertiesInterface) => this.bookService.retrieveDocument(props.bookUuid, props.chapterUuid, props.documentDpsId).pipe(
            map(document => {
                this.documentService.handleDocument(document);

                return LibraryActions.fetchDocumentSuccess({document});
            }),
            takeUntil(this.actions$.pipe(
                ofType(LibraryActions.fetchDocumentCancel),
            )),
        )),
    ));

    public fetchDocumentProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.fetchDocumentProgress),
            switchMap((props: LibraryDocumentProgressPropertiesInterface) => this.bookService.retrieveDocumentForProgress(props.bookUuid, props.chapterUuid, props.documentDpsId, props.userId).pipe(
                map(document => LibraryActions.fetchDocumentProgressSuccess({document})),
                catchError(error => of(LibraryActions.fetchDocumentProgressFailed({error, ...props}))),
            )),
        )
    });

    public finishAlgebrakitSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.algebrakitFinishSession),
            switchMap((props: AlgebrakitSessionPropertiesInterface) => this.documentService.finishAlgebrakitSession(props.sessionId).pipe(
                map(result => LibraryActions.finishAlgebrakitSessionSuccess({result, ...props})),
                catchError(error => of(LibraryActions.finishAlgebrakitSessionFailed({error, ...props}))),
            )),
        );
    });

    public newAlgebrakitSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.algebrakitNewSession),
            switchMap((props: AlgebrakitSessionPropertiesInterface) => this.documentService.getNewAlgebrakitSession(props.sessionId).pipe(
                map(session => LibraryActions.newAlgebrakitSessionSuccess({...props, ...session})),
                catchError(error => of(LibraryActions.newAlgebrakitSessionFailed({error, ...props}))),
            )),
        );
    });

    public updateDrillsterSessionScore$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.updateDrillsterSessionScore),
            switchMap((props: DrillsterSessionPropertiesInterface) => this.drillsterService.updateDrillsterSessionUpdateScore(props.code, props.id, props.score).pipe(
                map(drillsterSession => LibraryActions.updateDrillsterSessionScoreSuccess({drillsterSession, ...props})),
                catchError(error => of(LibraryActions.updateDrillsterSessionScoreFailed({error, ...props}))),
            )),
        );
    });

    public fetchDrillsterSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.fetchDrillsterSession),
            switchMap((props: DrillsterSessionPropertiesInterface) => this.drillsterService.getDrillsterSession(props.code, props.documentVersionId).pipe(
                map(drillsterSession => LibraryActions.fetchDrillsterSessionSuccess({drillsterSession, ...props})),
                catchError(error => of(LibraryActions.fetchDrillsterSessionFailed({error, ...props}))),
            )),
        );
    });

    public addSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.addSource),
            switchMap((props: {
                bookUuid: string,
                fileId: number,
                file: File,
                link: string,
                title: string,
                teacherOnly: boolean,
                chapterUuid: string,
                documentDpsId: string
            }) => this.sourceService.addSource(
                props.bookUuid,
                props.fileId,
                props.file,
                props.link,
                props.title,
                props.teacherOnly,
                props.chapterUuid,
                props.documentDpsId,
            ).pipe(
                map(sources => LibraryActions.addSourceSuccess({sources})),
                catchError(error => of(LibraryActions.addSourceFailed({error}))),
            )),
        );
    });

    public removeSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.removeSource),
            switchMap((props: { bookUuid: string, id: number }) => {
                return this.sourceService.removeSource(props.bookUuid, props.id)
                    .pipe(
                        map(() => LibraryActions.removeSourceSuccess()),
                        catchError(error => of(LibraryActions.removeSourceFailed({error}))),
                    );
            }),
        );
    });

    public addSourceFile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActionsEnum.addSourceFile),
            switchMap((props: { file: File }) => {
                return this.sourceService.addSourceFile(props.file)
                    .pipe(
                        map((response: FileResponseInterface) => LibraryActions.addSourceFileSuccess({file: response.data})),
                        catchError(error => of(LibraryActions.addSourceFileFailed({error}))),
                    );
            }),
        );
    });

    public fetchSources$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActions.fetchSources),
            switchMap((properties: LibrarySourcePropertiesInterface) => {
                return this.sourceService
                    .retrieveList(
                        properties.bookUuid,
                        properties.chapterUuids,
                        properties.documentDpsId,
                        properties.offset,
                        properties.limit,
                        properties.extensions,
                        properties.folderDocumentId,
                        properties.types,
                        properties.orderBy,
                    )
                    .pipe(
                        map(sourcesContainer => LibraryActions.fetchSourcesSuccess(sourcesContainer)),
                        catchError(error => of(LibraryActions.fetchSourcesFailed({error, ...properties}))),
                    );
            }),
        );
    });

    public fetchSourcesTeacherMaterials$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LibraryActions.fetchSourcesTeacherMaterials),
            switchMap((properties: LibrarySourcePropertiesInterface) => {
                return this.sourceService
                    .retrieveListTeacherMaterial(properties.bookUuid, properties.chapterUuids, properties.documentDpsId, properties.offset, properties.limit, properties.extensions, properties.folderDocumentId)
                    .pipe(
                        map(teacherSources => LibraryActions.fetchSourcesTeacherMaterialsSuccess(teacherSources)),
                        catchError(error => of(LibraryActions.fetchSourcesTeacherMaterialsFailed({error, ...properties}))),
                    );
            }),
        );
    });
}
