import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActionsSubject, Store} from '@ngrx/store';
import {OperatorFunction, Subscription} from 'rxjs';
import {LibraryActions, LibrarySelectors} from '@store/library';
import {BookDetailInterface, BookInterface, BookListItemInterface} from '@shared/interfaces/book.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {SingleSelectInterface} from '@shared/interfaces/single-select.interface';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-navigation-license',
    templateUrl: './navigation-license.component.html',
    styleUrls: ['./navigation-license.component.scss'],
})
export class NavigationLicenseComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    @Input()
    type?: string;

    @Input()
    paragraphBackground: boolean = false;

    book?: BookDetailInterface;

    bookUuid?: string;

    selected?: string;

    list: SingleSelectInterface[] = [];

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private actionsSubject: ActionsSubject, private store: Store) {
        this.subscriptions.push(
            this.store.select(LibrarySelectors.selectBook).subscribe(book => this.handleSelectBook(book)),
            this.store.select(LibrarySelectors.selectBooks)
                .pipe(filter(books => undefined !== books) as OperatorFunction<BookListItemInterface[] | undefined, BookListItemInterface[]>)
                .subscribe(books => this.handleSelectLibraryBooks(books)),
        );
    }

    ngOnInit(): void {
        this.store.dispatch(LibraryActions.fetchBooks({offset: 0, limit: -1, query: null, withoutProgress: true}));
    }

    interactLicenseNavigation(route: SingleSelectInterface): void {
        this.store.dispatch(LibraryActions.fetchBookCancel()); // Cancel possible previous fetch
        this.store.dispatch(LibraryActions.fetchBook({bookUuid: route.action}));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private handleSelectBook(book?: BookDetailInterface): void {
        if (undefined === book) {
            return;
        }

        const currentBook: BookDetailInterface | undefined = this.book;

        if (undefined !== currentBook && currentBook.uuid !== book.uuid) {
            this.router.navigate(['books', book.uuid]); // Go to book index when changing route
        } else {
            this.selected = this.getBookTitle(book);
            this.book = book;
        }
    }

    private handleSelectLibraryBooks(books: BookListItemInterface[]): void {
        this.list = books.map(book => {
            const title: string = this.getBookTitle(book);

            return {title: title, action: book.uuid};
        });
    }

    private getBookTitle(book: BookInterface): string {
        return [book.title, book.subTitle].filter(value => null !== value).join(' ');
    }
}
